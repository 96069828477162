import {reactive, ref} from "@vue/composition-api"
import {
    fetchAdvertisementType,
    fetchAdvertisersList,
    fetchBrowsers, fetchBrowserVersionsList, fetchCampaignsList,
    fetchDeviceList,
    fetchGeoList,
    fetchOSList, fetchOSVersionsList,
    fetchPlacementsList,
    fetchPlatformsList, fetchPresetsList,
    fetchPublishersList, fetchRegionsList
} from "@/api/lists"
import apiInstance from '@/libs/axios'
import {uniq} from "underscore"
import {objToQuery} from "@core/utils/utils"

export default function() {

    const publishersList = ref([])
    const platformsList = ref([])
    const placementsList = ref([])
    const advertisementTypesList = ref([])
    const advertisersList = ref([])
    const campaignsList = ref([])
    const devicesList = ref([])
    const geoList = ref([])
    const geoWhiteList = ref([])
    const geoBlackList = ref([])
    const osList = ref([])
    const browsersList = ref([])
    const browsersVersionsList = ref([])
    const osVersionsList = ref([])
    const regionsList = ref([])
    const presetsList = ref([])
    const currentDateString = ref(null)
    const todayDate = ref(null)
    const dateRange = ref({
        startDate: new Date(),
        endDate: new Date()
    })

    const publishersListNextPage = ref(null)
    const platformsListNextPage = ref(null)
    const placementsListNextPage = ref(null)
    const advertisersListNextPage = ref(null)
    const campaignsListNextPage = ref(null)
    const geoListNextPage = ref(null)
    const geoWhiteListNextPage = ref(null)
    const geoBlackListNextPage = ref(null)
    const osListNextPage = ref(null)
    const browsersListNextPage = ref(null)
    const browsersVersionsListNextPage = ref(null)
    const osVersionsListNextPage = ref(null)
    const regionsListNextPage = ref(null)
    const presetsListNextPage = ref(null)

    const publishersInitialList = ref([])
    const platformsInitialList = ref([])
    const placementsInitialList = ref([])
    const advertisersInitialList = ref([])
    const campaignsInitialList = ref([])
    const geoInitialList = ref([])
    const geoInitialWhiteList = ref([])
    const geoInitialBlackList = ref([])
    const osInitialList = ref([])
    const browsersInitialList = ref([])
    const browsersVersionsInitialList = ref([])
    const osVersionsInitialList = ref([])
    const regionsInitialList = ref([])
    const presetsInitialList = ref([])

    const publishersListQuery = reactive({})
    const campaignsListQuery = reactive({})
    const platformsListQuery = reactive({})
    const placementsListQuery = reactive({})
    const advertisersListQuery = reactive({})
    const geoListQuery = reactive({})
    const geoWhiteListQuery = reactive({})
    const geoBlackListQuery = reactive({})
    const osListQuery = reactive({})
    const browsersListQuery = reactive({})
    const browsersVersionsListQuery = reactive({})
    const osVersionsListQuery = reactive({})
    const regionsListQuery = reactive({})
    const presetsListQuery = reactive({})

    const namedLists = {
        publishers: [publishersList, publishersListNextPage, publishersInitialList, publishersListQuery],
        platforms: [platformsList, platformsListNextPage, platformsInitialList, platformsListQuery],
        placements: [placementsList, placementsListNextPage, placementsInitialList, placementsListQuery],
        advertisement_types: [advertisementTypesList],
        campaigns: [campaignsList, campaignsListNextPage, campaignsInitialList, campaignsListQuery],
        dsps: [advertisersList, advertisersListNextPage, advertisersInitialList, advertisersListQuery],
        device_types: [devicesList],
        geos: [geoList, geoListNextPage, geoInitialList, geoListQuery],
        whitelist_geo: [geoWhiteList, geoWhiteListNextPage, geoInitialWhiteList, geoWhiteListQuery],
        blacklist_geo: [geoBlackList, geoBlackListNextPage, geoInitialBlackList, geoBlackListQuery],
        oses: [osList, osListNextPage, osInitialList, osListQuery],
        browsers: [browsersList, browsersListNextPage, browsersInitialList, browsersListQuery],
        browser_versions: [browsersVersionsList, browsersVersionsListNextPage, browsersVersionsInitialList, browsersVersionsListQuery],
        os_versions: [osVersionsList, osVersionsListNextPage, osVersionsInitialList, osVersionsListQuery],
        regions: [regionsList, regionsListNextPage, regionsInitialList, regionsListQuery],
        presets: [presetsList, presetsListNextPage, presetsInitialList, presetsListQuery]
    }

    const availableLists = {
        publishers: fetchPublishersList,
        platforms: fetchPlatformsList,
        placements: fetchPlacementsList,
        advertisement_types: fetchAdvertisementType,
        dsps: fetchAdvertisersList,
        campaigns: fetchCampaignsList,
        device_types: fetchDeviceList,
        geos: fetchGeoList,
        whitelist_geo: fetchGeoList,
        blacklist_geo: fetchGeoList,
        oses: fetchOSList,
        browsers: fetchBrowsers,
        browser_versions: fetchBrowserVersionsList,
        os_versions: fetchOSVersionsList,
        regions: fetchRegionsList,
        presets: fetchPresetsList
    }

    let debounce = null

    const handleListsSearch = (event) => {
        if (event[1].length > 1 || (event[1].length === 0 && !event[2]?.length)) {
            clearTimeout(debounce)
            debounce = setTimeout(() => {
                listsSearch(event[0], event[1])
            }, 600)
        } else if (event[1].length === 0 && event[2]?.length) {
            clearTimeout(debounce)
            debounce = setTimeout(() => {
                listsSearch(event[0], event[1], event[2])
            }, 600)
        }
    }

    const listsSearch = (list, query, picked) => {
        if (!(query === '' || query.length >= 2)) return
        if (!availableLists[list]) return
        let queryFlag = list
        if (['blacklist_geo', 'whitelist_geo'].includes(list)) queryFlag = 'geos'

        setQuery(list, 'nameScope', query)
        if (query === '' && picked?.length) setQuery(list, queryFlag, picked)
        handleLoadLists([list])
        // if(query === '' && picked?.length) return availableLists[list](`${queryFlag}=${picked}`, 1)
        // return availableLists[list](`nameScope=${query}`, 1)
    }

    const handleLoadLists = (names, isLoading) => {
        return new Promise((resolve, reject) => {
            let namesLength = names.length
            names.forEach((name) => {
                let list
                let query
                if (Array.isArray(name)) {
                    list = name[0]
                    query = name[1]
                } else {
                    list = name
                    query = null
                }
                if (!availableLists[list]) return
                availableLists[list](objToQuery(namedLists[name][3]), 1)
                    .then((res) => {
                        namedLists[list][0].value = res.data.data
                        namedLists[list][1].value = res.data.links?.next || null
                        namedLists[list][2].value = uniq([...namedLists[list][2].value, ...res.data.data], (item) => item.id)
                        namesLength--
                        if (namesLength <= 1 && isLoading) isLoading.value = false
                        resolve(res.data.data)
                    })
            })
        })
    }

    const setQuery = (name, flag, query) => {
        if (!Array.isArray(query)) {
            namedLists[name][3][flag] = query
        } else {
            namedLists[name][3][flag] = query.filter((item) => item !== 'all')
        }
    }

    const getQuery = (name, flag) => {
        return namedLists[name][3][flag]
    }

    const resetAllQueries = (name) => {
        Object.assign(namedLists[name][3], {})
    }

    const handlePaginateList = (name) => {
        if (!namedLists[name][1].value) return

        apiInstance.get(namedLists[name][1].value)
            .then((res) => {
                namedLists[name][0].value.push(...res.data.data)
                namedLists[name][1].value = res.data.links?.next
                namedLists[name][2].value = uniq([...namedLists[name][2].value, ...res.data.data], (item) => item.id)
            })
    }

    return {
        publishersList,
        platformsList,
        placementsList,
        advertisementTypesList,
        advertisersList,
        campaignsList,
        devicesList,
        geoList,
        geoWhiteList,
        geoBlackList,
        osList,
        browsersList,
        browsersVersionsList,
        osVersionsList,
        regionsList,
        currentDateString,
        todayDate,
        dateRange,
        namedLists,
        presetsList,
        geoListNextPage,
        handleListsSearch,
        handleLoadLists,
        handlePaginateList,
        setQuery,
        getQuery,
        resetAllQueries
    }
}

