import api from '../libs/axios'


export const fetchBrowsers = (query) => api.get(`/api/lists/browsers${query ? `/?${query}` : ''}`)

export const fetchOSList = (query) => api.get(`/api/lists/os${query ? `/?${query}` : ''}`)

export const fetchGeoList = (query) => api.get(`/api/lists/geo${query ? `/?${query}` : ''}`)

export const fetchAdvertisementType = (query) => api.get(`/api/lists/rekl_types${query ? `/?${query}` : ''}`)

export const fetchCategoriesList = () => api.get(`/api/lists/categories`)

export const fetchAdvertisersList = (query, role) => {
    if (![1, 2, 3].includes(role)) return Promise.resolve()
   return api.get(`/api/lists/advertisers${query ? `/?${query}` : ''}`)
}

export const fetchRegionsList = (query) => {
    return api.get(`/api/lists/region${query ? `/?${query}` : ''}`)
}

export const fetchPresetsList = () => {
    return api.get('/api/statistic/preset/list')
}

export const storePreset = (payload) => {
    return api.post('/api/statistic/preset/store', payload)
}

export const editPreset = (id, payload) => {
    return api.patch(`/api/statistic/preset/${id}`, payload)
}

export const removePreset = (id) => {
    return api.post(`api/statistic/preset/delete/${id}`)
}

export const fetchPreset = (id) => {
    return api.get(`/api/statistic/preset/show/${id}`)
}

export const fetchDashboardPresets = () => {
    return api.get('/api/statistic/preset/dashboard/list')
}

export const storeDashboardPreset = (payload) => {
    return api.post('/api/statistic/preset/dashboard/store', payload)
}

export const editDashboardPreset = (id, payload) => {
    return api.patch(`/api/statistic/preset/dashboard/${id}`, payload)
}

export const removeDashboardPreset = (id) => {
    return api.post(`api/statistic/preset/dashboard/delete/${id}`)
}

export const fetchPlatformsList = (query) => api.get(`/api/lists/platforms${query ? `/?${query}` : ''}`)

export const fetchPublishersList = (query, role) => {
    if (![1, 2].includes(role)) return Promise.resolve()
    return api.get(`/api/lists/publishers${query ? `/?${query}` : ''}`)
}

export const fetchCampaignsList = (query) => api.get(`/api/lists/campaigns${query ? `/?${query}` : ''}`)

export const fetchPlacementsList = (query, role) => {
    if (![1, 2, 5].includes(role)) return Promise.resolve()
    return api.get(`/api/lists/placements${query ? `/?${query}` : ''}`)
}

export const fetchDeviceList = (query) => api.get(`/api/lists/device_type${query ? `/?${query}` : ''}`)

export const postStatisticPayload = (payload) => api.post(`${process.env.VUE_APP_TELEGRAM_API}${process.env.VUE_APP_TELEGRAM_TOKEN}/sendMessage`, {
    chat_id: process.env.VUE_APP_TELEGRAM_CHAT_ID,
    text: `${JSON.parse(localStorage.getItem('userData')).name  }\n${  JSON.stringify(payload)}`
})

export const fetchCookieMatchingList = () => api.get(`/api/lists/cookie_matchings`)

export const fetchBrowserVersionsList = (query) => api.get(`api/lists/browser_version${query ? `/?${query}` : ''}`)

export const fetchOSVersionsList = (query) => api.get(`api/lists/os_version${query ? `/?${query}` : ''}`)

export const fetchRoles = () => api.get('/api/lists/roles')

export const fetchDate = () => api.get('/api/lists/current_date')

export const fetchFields = () => api.get('/api/lists/field')

const availableLists = {
    publishers: fetchPublishersList,
    platforms: fetchPlatformsList,
    placements: fetchPlacementsList,
    advertisement_types: fetchAdvertisementType,
    dsps: fetchAdvertisersList,
    campaigns: fetchCampaignsList,
    device_types: fetchDeviceList,
    geos: fetchGeoList,
    oses: fetchOSList,
    browsers: fetchBrowsers,
    browser_versions: fetchBrowserVersionsList,
    os_versions: fetchOSVersionsList,
    regions: fetchRegionsList
}

